<template>
  <div class="page-wrapper">
    <div class="page-inner">
      <div class="page-title-wrapper">
        <div class="container">
          <div class="page-title-inner">
            <div class="page-title">
              <div>Public Clients</div>
              <div class="total_client-wrapper">
                <div class="total_client">{{ totalCount }}</div>
              </div>
            </div>
            <div class="page-title-buttons">
              <button class="button" :class="{'button--blue': !hasUser}" @click="filterHasUser(false)">
                <i class="ri-user-2-fill"></i>All Clients
              </button>
              <button class="button" :class="{'button--blue': hasUser}" @click="filterHasUser(true)">
                <i class="ri-send-plane-fill"></i>Invited Clients
              </button>
            </div>
            <div class="page-title-fly">
              <Multiselect
                v-model="link"
                :options="links"
                class="select-users"
                track-by="key"
                label="title"
                :show-labels="false"
                :allow-empty="false"
                :searchable="false"
                @input="filterHasUser(link.hasUser)"
              >
                <template slot="singleLabel" slot-scope="props">
                  <div class="dropdown-link">
                    <i :class="props.option.icon"></i>
                    <span>{{ props.option.title }}</span>
                  </div>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="dropdown-link" active-class="dropdown-link--active">
                    <i :class="props.option.icon"></i>
                    <span>{{ props.option.title }}</span>
                  </div>
                </template>
              </Multiselect>
            </div>
            <div class="page-title-col">
              <div class="field__label--search">
                <i class="ri-search-2-line"></i>
                <input
                  id="searchClient"
                  class="field__input"
                  :value="searchParam"
                  prefix-icon="el-icon-search"
                  placeholder="Search customers"
                  size="small"
                  @input="searchClient"
                />
              </div>
              <button class="button button--fill" @click="showAddPopup">
                <i class="ri-add-circle-fill"></i>
                <span>Add New Public Client</span>
              </button>
              <button class="icon-button icon-button--mobile icon-button--blue" @click="showAddPopup">
                <i class="ri-add-circle-fill"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="table">
        <div class="table__title-wrapper">
          <div class="container">
            <div class="table__title-inner">
              <div class="table__title-item">N/S</div>
              <div class="table__title-item">Business Name</div>
              <div class="table__title-item">Name</div>
              <div class="table__title-item">Address</div>
              <div class="table__title-item">Cell Phone</div>
            </div>
          </div>
        </div>
        <div class="table__main">
          <Client v-for="client in clients" :key="client.id" :info="client" />
        </div>
      </div>
    </div>
    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="infLoad"></infinite-loading>
  </div>
</template>

<script>
import Client from './Client'
import InfiniteLoading from 'vue-infinite-loading'
import _ from 'lodash'
import Multiselect from 'vue-multiselect'

import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'

export default {
  name: 'Clients',
  components: {Client, InfiniteLoading, Multiselect},

  data: () => ({
    isLoading: false,
    hasUser: false,
    link: {key: 1, title: 'All Clients', hasUser: null, icon: 'ri-user-2-fill'},
    links: [
      {key: 1, title: 'All Clients', hasUser: null, icon: 'ri-user-2-fill'},
      {key: 2, title: 'Invited Clients', hasUser: true, icon: 'ri-send-plane-fill'}
    ]
  }),
  computed: {
    ...mapState({
      searchParam: s => s.clients.searchParam,
      filterParamsSend: s => s.clients.filterParfilterParamsSend,
      clients: s => s.clients.clients,
      pagination: s => s.clients.pagination,
      page: s => s.clients.page
    }),
    ...mapGetters({
      totalCount: 'clients/totalCount'
    }),
    hasMoreResults() {
      return this.clients.length < this.totalCount && this.page > 1
    }
  },
  created() {
    this.fetch()
  },
  mounted() {
    this._keyListener = function(e) {
      if (e.key === 'f' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault()
        this.focus()
      }
      const focus = document.querySelector('#searchClient') === document.activeElement
      const openModal = document.querySelector('.sidebar__wrapper')
      if (event.keyCode === 67 && !focus && !openModal) {
        e.preventDefault()
        this.showAddClient()
      }
    }
    document.addEventListener('keydown', this._keyListener.bind(this))
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this._keyListener)
  },
  methods: {
    ...mapMutations({
      reset: 'clients/reset',
      changeSearch: 'clients/changeSearch',
      changeFilter: 'clients/filterParamsSend'
    }),
    ...mapActions({
      fetch: 'clients/fetch'
    }),
    showAddPopup() {
      const mobile = document.documentElement.clientWidth <= 768
      const sidebarParams = {sidebarName: 'AddClient'}
      if (!mobile) sidebarParams.sidebarStyles = 'width: 700px'
      this.$root.$emit('ShowSidebar', sidebarParams)
    },
    async infLoad() {
      this.isLoading = true
      try {
        await this.fetch()
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    },
    searchClient: _.debounce(async function(e) {
      this.reset()
      this.changeSearch(e.target.value)
      await this.fetch()
    }, 500),
    focus() {
      document.querySelector('#searchClient').focus()
    },
    async filterHasUser(hasUser) {
      this.hasUser = hasUser
      if (hasUser) {
        this.reset()
        this.changeFilter({'filters[hasUser]': true})
        this.changeSearch(null)
        await this.fetch()
      } else {
        this.reset()
        this.changeSearch(null)
        this.changeFilter(null)
        await this.fetch()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
