var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-wrapper" },
    [
      _c("div", { staticClass: "page-inner" }, [
        _c("div", { staticClass: "page-title-wrapper" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "page-title-inner" }, [
              _c("div", { staticClass: "page-title" }, [
                _c("div", [_vm._v("Public Clients")]),
                _c("div", { staticClass: "total_client-wrapper" }, [
                  _c("div", { staticClass: "total_client" }, [
                    _vm._v(_vm._s(_vm.totalCount))
                  ])
                ])
              ]),
              _c("div", { staticClass: "page-title-buttons" }, [
                _c(
                  "button",
                  {
                    staticClass: "button",
                    class: { "button--blue": !_vm.hasUser },
                    on: {
                      click: function($event) {
                        return _vm.filterHasUser(false)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "ri-user-2-fill" }),
                    _vm._v("All Clients ")
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "button",
                    class: { "button--blue": _vm.hasUser },
                    on: {
                      click: function($event) {
                        return _vm.filterHasUser(true)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "ri-send-plane-fill" }),
                    _vm._v("Invited Clients ")
                  ]
                )
              ]),
              _c(
                "div",
                { staticClass: "page-title-fly" },
                [
                  _c("Multiselect", {
                    staticClass: "select-users",
                    attrs: {
                      options: _vm.links,
                      "track-by": "key",
                      label: "title",
                      "show-labels": false,
                      "allow-empty": false,
                      searchable: false
                    },
                    on: {
                      input: function($event) {
                        return _vm.filterHasUser(_vm.link.hasUser)
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "singleLabel",
                        fn: function(props) {
                          return [
                            _c("div", { staticClass: "dropdown-link" }, [
                              _c("i", { class: props.option.icon }),
                              _c("span", [_vm._v(_vm._s(props.option.title))])
                            ])
                          ]
                        }
                      },
                      {
                        key: "option",
                        fn: function(props) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-link",
                                attrs: {
                                  "active-class": "dropdown-link--active"
                                }
                              },
                              [
                                _c("i", { class: props.option.icon }),
                                _c("span", [_vm._v(_vm._s(props.option.title))])
                              ]
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.link,
                      callback: function($$v) {
                        _vm.link = $$v
                      },
                      expression: "link"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "page-title-col" }, [
                _c("div", { staticClass: "field__label--search" }, [
                  _c("i", { staticClass: "ri-search-2-line" }),
                  _c("input", {
                    staticClass: "field__input",
                    attrs: {
                      id: "searchClient",
                      "prefix-icon": "el-icon-search",
                      placeholder: "Search customers",
                      size: "small"
                    },
                    domProps: { value: _vm.searchParam },
                    on: { input: _vm.searchClient }
                  })
                ]),
                _c(
                  "button",
                  {
                    staticClass: "button button--fill",
                    on: { click: _vm.showAddPopup }
                  },
                  [
                    _c("i", { staticClass: "ri-add-circle-fill" }),
                    _c("span", [_vm._v("Add New Public Client")])
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass:
                      "icon-button icon-button--mobile icon-button--blue",
                    on: { click: _vm.showAddPopup }
                  },
                  [_c("i", { staticClass: "ri-add-circle-fill" })]
                )
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "table" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "table__main" },
            _vm._l(_vm.clients, function(client) {
              return _c("Client", { key: client.id, attrs: { info: client } })
            }),
            1
          )
        ])
      ]),
      !_vm.isLoading && _vm.hasMoreResults
        ? _c("infinite-loading", { on: { infinite: _vm.infLoad } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table__title-wrapper" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "table__title-inner" }, [
          _c("div", { staticClass: "table__title-item" }, [_vm._v("N/S")]),
          _c("div", { staticClass: "table__title-item" }, [
            _vm._v("Business Name")
          ]),
          _c("div", { staticClass: "table__title-item" }, [_vm._v("Name")]),
          _c("div", { staticClass: "table__title-item" }, [_vm._v("Address")]),
          _c("div", { staticClass: "table__title-item" }, [
            _vm._v("Cell Phone")
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }