<template>
  <div class="table__row">
    <div class="container">
      <div class="table__row-inner">
        <div class="table__cell">
          <div class="table__cell-inner">
            <div v-if="info.northSouth" class="indicator indicator--opacity indicator--orange">N</div>
            <div v-else class="indicator indicator--opacity indicator--blue">S</div>
          </div>
        </div>
        <div class="table__cell">
          <div class="table__cell-title">{{ info.businessName }}</div>
        </div>
        <div class="table__cell">
          <div class="table__cell-title">{{ info.firstName }} {{ info.middleInitial }} {{ info.lastName }}</div>
        </div>
        <div class="table__cell">
          <div class="table__cell-title">{{ info.address }}</div>
        </div>
        <div class="table__cell">
          <div class="table__cell-title table__cell--link" @click.stop>
            <a :href="'tel:' + info.cellPhone">{{ info.cellPhone }}</a>
          </div>
        </div>
        <div class="table__cell table__cell--end" @click.stop>
          <el-dropdown trigger="click" @command="action">
            <i class="ri-more-fill"></i>
            <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--actions">
              <el-dropdown-item class="item--edit" command="Edit">
                <i class="ri-pencil-fill"></i> Edit
              </el-dropdown-item>
              <el-dropdown-item class="item--remove" command="Remove">
                <i class="ri-delete-bin-7-fill"></i> Remove
              </el-dropdown-item>
              <el-dropdown-item
                class="item--invite"
                :class="{'item--invite--unactive': !info.cellPhone || info.user}"
                command="Invite"
              >
                <i class="ri-send-plane-fill"></i> Invite
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="table__row-card card">
        <div class="card__header">
          <div class="card__box">
            <div class="letter">{{ info.businessName[0] }}</div>
          </div>
          <div class="card__box">
            <div class="card__title">{{ info.businessName }}</div>
            <div class="card__subtitle">{{ info.businessPhone }}</div>
          </div>
          <div class="card__box" @click.stop.self>
            <el-dropdown trigger="click" @command="action">
              <i class="ri-more-fill"></i>
              <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--actions">
                <el-dropdown-item class="item--edit">
                  <a v-if="info.businessPhone" class="card__link" :href="'tel:' + info.businessPhone">
                    <i class="ri-phone-fill"></i>Call
                  </a>
                </el-dropdown-item>
                <el-dropdown-item class="item--edit" command="Edit">
                  <i class="ri-pencil-fill"></i> Edit
                </el-dropdown-item>
                <el-dropdown-item class="item--remove" command="Remove">
                  <i class="ri-delete-bin-7-fill"></i> Remove
                </el-dropdown-item>
                <el-dropdown-item
                  class="item--invite"
                  :class="{'item--invite--unactive': !info.cellPhone || info.user}"
                  command="Invite"
                >
                  <i class="ri-send-plane-fill"></i> Invite
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Client',
  props: {
    info: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    value: 'dropdown',
    options: ['Edit', 'Remove', 'Invite']
  }),
  methods: {
    async action(act) {
      if (act === 'Edit') {
        const mobile = document.documentElement.clientWidth <= 768
        const sidebarParams = {sidebarName: 'AddClient', componentData: this.info}
        if (!mobile) sidebarParams.sidebarStyles = 'width: 700px'
        this.$root.$emit('ShowSidebar', sidebarParams)
      } else if (act === 'Remove') {
        this.open()
      } else if (act === 'Invite') {
        if (!this.info.cellPhone || this.info.user) return
        try {
          await this.$store.dispatch('invite/invite', this.info.id)
        } catch (err) {
          this.$store.commit('setError', err)
        }
      }
    },
    open() {
      this.$confirm('Are You Sure?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showClose: false,
        customClass: 'el-message-box__remove'
      })
        .then(async () => {
          await this.$store.dispatch('clients/delete', this.info.id)
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
